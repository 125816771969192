// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'

// // Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]

export default [
  {
    title: "Dashboard",
    route: "admin-dashboard",
    icon: "CompassIcon",
  },

  {
    title: "Users",
    icon: "UsersIcon",
    children: [
      {
        title: "Customer/Student",
        route: "admin-pengguna-customer",
        icon: "UsersIcon",
      },
      {
        title: "Admin",
        route: "admin-pengguna",
        icon: "UserPlusIcon",
      },
    ],
  },
  {
    title: "Try Out",
    icon: "CheckCircleIcon",
    // header: "Jadwal Try Out & Soal Ujian",
    children: [
      {
        title: "Jadwal",
        route: "admin-jadwal",
        icon: "ListIcon",
      },
      // {
      //   title: "Kedinasan & Bintara",
      //   route: "admin-tryout-dinbin",
      //   icon: "ListIcon",
      // },
      {
        title: "Ujian/Subtest",
        route: "admin-ujian",
        icon: "ListIcon",
      },
      {
        title: "Bank Soal",
        route: "admin-bank-soal",
        icon: "ListIcon",
      },
      {
        title: "Bank video",
        route: "admin-bank-soal",
        icon: "ListIcon",
      },
    ],
  },
  {
    title: "Paket",
    icon: "PackageIcon",
    // header: "Jadwal Try Out & Soal Ujian",
    children: [
      {
        title: "Paket Try Out",
        route: "admin-paket-tryout",
        icon: "StarIcon",
      },
      {
        title: "SNBP",
        route: "admin-snbp",
        icon: "AwardIcon",
      },
    ],
  },
  // {
  //   title: "SNBP",
  //   icon: "ApertureIcon",
  //   // header: "Jadwal Try Out & Soal Ujian",
  //   children: [
  //     {
  //       title: "Setting",
  //       route: "admin-snbp-setting",
  //       icon: "SettingsIcon",
  //     },
  //     // {
  //     //   title: "Member",
  //     //   route: "admin-snbp-member",
  //     //   icon: "UsersIcon",
  //     // },
  //     {
  //       title: "Voucher",
  //       route: "admin-snbp-voucher",
  //       icon: "PercentIcon",
  //     },
  //     {
  //       title: "List",
  //       route: "admin-snbp",
  //       icon: "ListIcon",
  //     },
  //   ],
  // },
  {
    title: "Payments",
    icon: "CreditCardIcon",
    // header: "Jadwal Try Out & Soal Ujian",
    children: [
      {
        title: "Rekening",
        route: "admin-payment",
        icon: "CreditCardIcon",
      },
      {
        title: "Transaksi",
        icon: "ListIcon",
        children: [
          {
            title: "Paket Try Out",
            route: "admin-transaksi",
            icon: "CreditCardIcon",
          },
          {
            title: "SNBP",
            route: "admin-transaksi-snbp",
            icon: "CreditCardIcon",
          },
          {
            title: "Ebook",
            route: "admin-transaksi-ebook",
            icon: "CreditCardIcon",
          },
        ],
      },
      // {
      //   title: "Transaksi Manual",
      //   icon: "ListIcon",
      //   children: [
      //     {
      //       title: "Paket",
      //       icon: "CreditCardIcon",
      //       route: "admin-transaksi-manual",
      //     },
      //     {
      //       title: "Ebook",
      //       icon: "CreditCardIcon",
      //       route: "admin-transaksi-ebook-manual",
      //     },
      //   ],
      // },
      {
        title: "Voucher",
        route: "admin-voucher",
        icon: "PercentIcon",
      },
    ],
  },
  {
    title: "E-Book",
    icon: "BookIcon",
    children: [
      {
        title: "E-Book",
        route: "admin-ebook",
        icon: "BookOpenIcon",
      },
      {
        title: "Kategori Ebook",
        route: "admin-kategori-paket-ebook",
        icon: "BookIcon",
      },
    ],
  },
  {
    title: "Sekolah/Universitas",
    icon: "BriefcaseIcon",
    children: [
      {
        title: "Rumpun",
        route: "admin-rumpun",
        icon: "BriefcaseIcon",
      },
      {
        title: "Universitas",
        route: "admin-universitas",
        icon: "BriefcaseIcon",
      },
      {
        title: "Sekolah",
        route: "admin-sekolah",
        icon: "BriefcaseIcon",
      },
      {
        title: "Mata Pelajaran",
        route: "admin-mata-pelajaran",
        icon: "BookmarkIcon",
      },
    ],
  },
  // {
  //   header: "d",
  // },
  {
    title: "Konten Website",
    icon: "GlobeIcon",
    children: [
      {
        title: "Banner",
        route: "admin-banner",
        icon: "SlidersIcon",
      },
      {
        title: "Pop Up",
        route: "admin-popup",
        icon: "SlidersIcon",
      },
      {
        title: "Kategori Artikel & Halaman",
        route: "admin-kategori-artikel",
        icon: "TagIcon",
      },
      {
        title: "Artikel",
        route: "admin-artikel",
        icon: "FileTextIcon",
      },
      {
        title: "Halaman",
        route: "admin-halaman",
        icon: "FileTextIcon",
      },
      {
        title: "Panduan",
        route: "admin-panduan",
        icon: "BookOpenIcon",
      },
      {
        title: "FAQ",
        route: "admin-faq",
        icon: "HelpCircleIcon",
      },
    ],
  },

  {
    title: "Role Management",
    route: "admin-role-management",
    icon: "SlidersIcon",
  },
  {
    title: "Level",
    route: "admin-level",
    icon: "UserCheckIcon",
  },
  {
    title: "Menu",
    route: "admin-menu",
    icon: "ServerIcon",
  },
];
